import {
	News,
	Post,
	SportId,
	StatRankingAttribute,
	StatSeason,
	StatTRound,
	Sport,
	StatTeam,
	Tag,
	TagTeamExtra,
	TagTeamType,
} from '@ui-kit/main-api-types';
import { getFirstLastName } from '@ui-kit/main-frontend-ui-kit/dist/src/utils/helpers';
import { StatMatch } from '@ui-kit/main-int-api-types';
import dayjs from 'dayjs';

import { BreadCrumbsJsonLdItem } from '@utils/json-ld';
import { LANG, TagTeamDirectories } from '@utils/types';

import { BETTING_SPORT_URLS } from './bookie';
import { SPORT_NEWS_PAGE_URLS } from './constants';
import { getMatchLink } from './helpers';

import { addIfArr } from './helpers';

interface ClubBreadCrumbsItemsProps {
	t: any;
	tag: Tag;
	sport?: Sport;
	locale?: string;
	tagPageName?: string;
	tagPagePath?: string;
	tagPageParamsName?: string;
	tagPageParamsPath?: string;
	tagPageSecondParamsName?: string;
	tagPageSecondParamsPath?: string;
	tagPageThirdParamsName?: string;
	tagPageThirdParamsPath?: string;
}

export const getClubBreadCrumbsItems = ({
	t,
	tag,
	sport,
	locale,
	tagPageName,
	tagPagePath,
	tagPageParamsName,
	tagPageParamsPath,
	tagPageSecondParamsName,
	tagPageSecondParamsPath,
	tagPageThirdParamsName,
	tagPageThirdParamsPath,
}: ClubBreadCrumbsItemsProps): BreadCrumbsJsonLdItem[] => {
	const tagLink = tag?.hru;
	const tagName = tag?.title?.[locale.toUpperCase()] || tag?.statObject?.name;
	const teamType = (tag?.extra as TagTeamExtra)?.type;
	const tagTypePath =
		teamType === TagTeamType.National
			? TagTeamDirectories.Team
			: TagTeamDirectories.Clubs;
	const tagTypeName =
		teamType === TagTeamType.National
			? t('translations.teams')
			: t('translations.clubs');
	const statObject = tag?.statObject as StatTeam;
	const leagueName =
		statObject?.homeTournament?.tag?.title?.[locale] ||
		statObject?.homeTournament?.name;
	const leagueHru = statObject?.homeTournament?.tag?.hru;

	const breadcrumbs: BreadCrumbsJsonLdItem[] = [
		{
			item: `https://tribuna.com/${locale}/`,
			name: 'Tribuna',
		},
		...addIfArr(!!sport && sport.id !== SportId.Football, [
			{
				name: sport?.name?.[locale],
				item: `https://tribuna.com/${locale}/${sport?.path}/`,
			},
		]),
		{
			name: leagueName || tagTypeName,
			item: `https://tribuna.com/${locale}/${
				leagueHru ? `league/${leagueHru}` : tagTypePath
			}/`,
		},
		{
			name: tagName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/`,
		},
	];

	if (tagPageName) {
		breadcrumbs.push({
			name: tagPageName,
			item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/`,
		});
	}
	if (tagPageParamsName) {
		breadcrumbs.push({
			name: tagPageParamsName,
			...(tagPageParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/`,
			}),
		});
	}
	if (tagPageSecondParamsName) {
		breadcrumbs.push({
			name: tagPageSecondParamsName,
			...(tagPageSecondParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageSecondParamsPath}/`,
			}),
		});
	}
	if (tagPageThirdParamsName) {
		breadcrumbs.push({
			name: tagPageThirdParamsName,
			...(tagPageThirdParamsPath && {
				item: `https://tribuna.com/${locale}/${tagTypePath}/${tagLink}/${tagPagePath}/${tagPageParamsPath}/${tagPageSecondParamsPath}/${tagPageThirdParamsPath}/`,
			}),
		});
	}

	delete breadcrumbs?.[breadcrumbs?.length - 1]?.item;

	return breadcrumbs;
};

interface IGetMainPageJsonLd {
	season: StatSeason;
	news: News[];
	posts: Post[];
	matches: StatMatch[];
	locale: string;
}

export const getMainPageJsonLd = ({
	season,
	locale,
	news,
	posts,
	matches,
}: IGetMainPageJsonLd) => {
	const mainPageUrl =
		locale === LANG.en
			? 'https://tribuna.com/'
			: `https://${locale}.tribuna.com/`;

	const reversedRounds: StatTRound[] = season?.standingsBracketsStages
		?.reduce((acc, round) => {
			if (
				('includeStandings' in round && round?.includeStandings) ||
				'layers' in round
			) {
				acc.push(round);
			}
			return acc;
		}, [])
		?.reverse();

	const initialRound = reversedRounds?.[0];
	const initialStandings = initialRound?.teamStanding?.total;
	const clubs = initialStandings?.map(el => el?.team);
	const playersStat = season?.rankingPlayerStat?.find(
		el => el?.attribute === StatRankingAttribute.TotalGoals,
	)?.items;

	return {
		'@context': 'https://schema.org',
		'@graph': [
			{
				'@type': 'WebSite',
				name: 'Tribuna.com',
				alternateName: ['Tribuna'],
				url: mainPageUrl,
				inLanguage: locale,
				publisher: {
					'@type': 'NewsMediaOrganization',
					name: 'Tribuna.com',
					url: mainPageUrl,
					logo: {
						'@type': 'ImageObject',
						url: 'https://tribuna.com/seo_logo.svg',
						width: 157,
						height: 112,
					},
					sameAs: [
						'https://www.linkedin.com/company/tribuna-com/',
						'https://www.facebook.com/footballtribuna/',
					],
				},
				hasPart: [
					...news
						?.filter(news => !news?.link)
						?.map(news => {
							const {
								mainSport,
								title,
								commentsCount,
								id,
								hru,
								mainPhoto,
								shareInfo,
							} = news;
							const datePublished = dayjs(
								+news?.publicationTime * 1000,
							).format();

							const newsDirectory =
								mainSport?.id === SportId?.Football
									? 'news'
									: SPORT_NEWS_PAGE_URLS?.[locale];

							const sportPath =
								mainSport?.id === SportId?.Football
									? ''
									: mainSport?.id === SportId.Bet
										? `${BETTING_SPORT_URLS?.[locale]}/`
										: `${mainSport?.path}/`;

							const linkToNews = `https://tribuna.com/${locale}/${sportPath}${newsDirectory}/${hru || id}/`;

							const mainImageUrl = mainPhoto?.url || shareInfo?.picture?.url;

							const displayAuthor = news?.displayAuthor?.[0]?.user;
							const author = displayAuthor?.id
								? displayAuthor
								: news?.author?.user;

							return {
								'@type': 'NewsArticle',
								headline: title?.defaultValue,
								datePublished,
								commentCount: commentsCount,
								articleSection: mainSport?.name?.defaultValue,
								url: linkToNews,
								image: mainImageUrl || {
									'@type': 'ImageObject',
									url: 'https://pictures.tribuna.com/image/091e7038-ef22-4d46-b3c9-9a72ae329844/',
									width: 1200,
									height: 628,
								},
								author: {
									'@type': 'Person',
									name: author?.name || author?.nickname,
									url: `https://tribuna.com/${locale}/profile/${author?.id}/`,
								},
								mainEntityOfPage: {
									'@type': 'WebPage',
									'@id': linkToNews,
								},
							};
						}),
					...posts
						?.filter(post => !post?.link)
						?.map(post => {
							const {
								blog,
								hru,
								id,
								title,
								mainSport,
								commentsCount,
								displayAuthor,
								mainPhoto,
							} = post;

							const datePublished = dayjs(
								+post?.publicationTime * 1000,
							).format();

							const blogHru = blog?.hru;
							const blogId = blog?.id;
							const blogLinkID = blogHru || blogId;
							const postHruOrId = hru || id;

							const sportPath =
								mainSport?.id === SportId?.Football
									? ''
									: mainSport?.id === SportId.Bet
										? `${BETTING_SPORT_URLS?.[locale]}/`
										: `${mainSport?.path}/`;
							const postLink = blogLinkID
								? `https://tribuna.com/${locale}/blogs/${blogLinkID}/${postHruOrId}/`
								: `https://tribuna.com/${locale}/${sportPath}blogs/${postHruOrId}/`;
							const blogLink = `https://tribuna.com/${locale}/blogs/${blogLinkID}/`;

							const author = displayAuthor?.[0]?.user?.id
								? displayAuthor?.[0]?.user
								: post?.author?.[0]?.user;

							return {
								'@type': 'BlogPosting',
								headline: title?.defaultValue || title?.[locale],
								datePublished,
								articleSection: mainSport?.name?.defaultValue,
								commentCount: commentsCount,
								url: postLink,
								image: mainPhoto?.url || {
									'@type': 'ImageObject',
									url: 'https://pictures.tribuna.com/image/091e7038-ef22-4d46-b3c9-9a72ae329844/',
									width: 1200,
									height: 628,
								},
								...(!!blog && {
									isPartOf: {
										'@type': 'Blog',
										name: blog?.title,
										url: blogLink,
									},
								}),
								author: {
									'@type': 'Person',
									name: author?.name || author?.nickname,
									url: `https://tribuna.com/${locale}/profile/${author?.id}/`,
								},
								mainEntityOfPage: {
									'@type': 'WebPage',
									'@id': postLink,
								},
							};
						}),
					...matches?.slice(0, 9)?.map(match => {
						const matchLink = getMatchLink(match, locale);
						return {
							'@type': 'WebPage',
							url: matchLink,
							mainEntityOfPage: {
								'@type': 'WebPage',
								'@id': matchLink,
							},
						};
					}),
					...clubs?.map(({ url, name }) => ({
						'@type': 'WebPage',
						url,
						mainEntityOfPage: { '@type': 'WebPage', '@id': url },
						about: { '@type': 'SportsOrganization', name, url },
					})),
					...playersStat?.map(({ player, team }) => {
						const playerName = getFirstLastName(
							player?.firstName,
							player?.lastName,
						);
						return {
							'@type': 'WebPage',
							url: player?.url,
							mainEntityOfPage: {
								'@type': 'WebPage',
								'@id': player?.url,
							},
							about: {
								'@type': 'Person',
								name: playerName,
								url: player?.url,
								worksFor: {
									'@type': 'SportsOrganization',
									name: team?.name,
									url: team?.url,
								},
							},
						};
					}),
				],
			},
		],
	};
};
